html
  background $color-white
  &.secure
    background $color-mine-shaft
body
  background transparent

.page-container
  padding-top 64px
  box-sizing border-box
  color $text-color-inverse
  md-divider
    border-color $text-color

md-toolbar.page-header
  position fixed
  left 0
  top 0
  right 0
  background $color-primary

.toolbar-back
.toolbar-logout
  font-size .75em
  line-height 20px
  vertical-align middle
.toolbar-back
  &:before
    content '‹ '
    display inline-block
    font-size: 2em
    height 20px
    line-height 20px
    padding-right .3em
    vertical-align middle
    margin-top -0.25em
.toolbar-right
  text-align right

md-dialog-content
  color $text-color-inverse

md-toolbar.md-default-theme:not(.md-menu-toolbar), md-toolbar:not(.md-menu-toolbar)
  background $color-primary