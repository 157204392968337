truncate()
  white-space nowrap
  overflow hidden
  text-overflow ellipsis

absolute()
  position absolute
  left 0
  top 0
  right 0
  bottom 0