a[href="#type834a8"]
  display none!important


.page-section-drawing
  position relative

.page-section-details
  min-width 25vh
  width 35%
  //border-top 1px solid lighten($color-black, 50)
  background-color #323232
  position absolute
  right 0
  top 64px
  bottom 0

.page-section-action-bar
  position fixed
  left 20px
  bottom 20px


.cut-details-parts
  overflow-y auto
  overflow-x hidden
  flex-shrink 0
  flex-grow 0
  height calc(70% - 1px)

.cut-details-sheets
  color $text-color-inverse
  border-top 1px solid #4d4d4d
  overflow-y auto
  flex-shrink 0
  flex-grow 0
  height 30%
  md-radio-button
    width 1.75em
    margin 5px 30px 15px 0
    font-weight bold
    &.md-checked
      color $color-active-background
      &:not([disabled])
        .md-on
          background-color $color-active-background
        .md-off
          border-color $color-active-background

    &:not([disabled])
      .md-on
        background-color $text-color
      .md-off
        border-color $text-color
    &:focus
      outline none
    &.is-done
      &:not([disabled])
        color $color-done
        .md-on
          background-color $color-done
        .md-off
          border-color $color-done
    .md-label
      margin-left 4px

.cut-details-sheets-headline
  width 100%
  font-size 1.1em
  margin-top .75em
  margin-bottom .5em

.cut-drawing
  width 65%
  padding-top 7.5vh


.cut-drawing-inner
  width 75vh
  height 75vh
  position relative
  margin auto

  #results &
    width 250px
    height 250px
    font-size .65rem
    .cut-sheet-mesures
      color inherit

.cut-sheet
  width 100px
  height 100px
  left 50%
  top 50%
  transform translate(-50%, -50%)
  &:before
    absolute()
    content ''
    margin -2px
    border 2px solid $color-grey-darkest
    background $color-white url('../images/wood-grain-direction-text-50.png') center center
  &.is-rotatable:before
    background-image none

.cut-sheet
.cut-part
.cut-remaining
  position absolute
  .is-hidden-width .cut-sheet-mesures.is-width
  .is-hidden-width .cut-sheet-mesures.is-width
  .is-hidden-height .cut-sheet-mesures.is-height
  .is-hidden-position .cut-part-position
  .is-hidden-name .cut-part-notice
  .is-hidden-name .cut-part-name
    display none

.cut-sheet
  box-shadow 3px 4px 15px rgba($color-black, .4)

.cut-part
.cut-remaining
  overflow hidden
  &:before
    absolute()
    margin 1px
    content ''
    border 1px solid $color-grey-darkest
    transition background-color 300ms
  &.is-done
    color darken($text-color, 5)
    &:before
      background-color $color-done-background
  &.is-active
    &:before
      background $color-active-background
  &.is-excluded
    &:before
      background none
    .cut-sheet-mesures
      background $color-white

.cut-part
  [ng-controller="cuttingSheetController"] &
    cursor pointer
  &:before
    background-color rgba($color-mercury, .8)

.cut-remaining
  &:before
    background rgba($color-mercury, .8) url('../images/remaining.png') center center
  .cut-sheet-mesures
    background $color-mercury

.cut-sheet-mesures
  position absolute
  color inherit
  line-height 1em
  right .2em
  top .3em
  padding .2em
  white-space nowrap
  z-index 100
  color $text-color
  #results &
    font-size .9em

  &.is-sheet
    text-align center
    font-size 1.3em
    color $text-color-inverse
    #results &
      font-size 1em
    &.is-width
      top auto
      bottom 100%
      left 50%
      right auto
      padding-bottom 5px
      transform translateX(-50%)
    &.is-height
      bottom 50%
      right calc(100% - .6em)
      left auto
      transform translateY(-50%), rotate(-90deg)
      width auto

  &.is-height
    bottom 1em
    top auto
    left 0
    right auto
    margin -.2em 0 0 -.3em
    transform rotate(-90deg)
    width 2.1em

  &.is-description
    font-size 120%
    top 50%
    left 50%
    right auto
    bottom auto
    height 1.2em
    white-space normal
    text-align center
    transform translate(-50%, -50%)
    height auto
    padding 0

.cut-part-position
  padding .2em

.cut-part-name
  font-size .8em
  display none
  padding .3em

.cut-part-notice
  font-size .7em
  padding .3em
  #results &
    font-size 1em

.toolbar-title
  text-overflow ellipsis
  white-space nowrap
  overflow hidden
  text-align center

.md-list-item-text.layout-fill
  min-height 0

.md-list-caption
  font-size .875em
  color $color-grey-darker
  min-height 28px

md-list-item
  position relative
  transition-property color
  transition-duration 300ms
  .md-list-item-inner > *
  .md-list-item-inner .md-title
  .md-list-item-inner .md-caption
  .md-list-caption
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  .md-caption
    font-size .75em
    color $color-grey-darker
    padding-left .2em
  .md-button
    line-height inherit
  .md-subhead
    line-height inherit
  &:before
    absolute()
    content ''
    transition background-color 300ms
  &.is-done
  &.is-active
    color darken($text-color, 5)
    &:before
      background-color $color-done-background
    md-checkbox .md-icon
      border-color $text-color
  &.is-active
    color $text-color
    &:before
      background-color $color-active-background
  &.is-part
    &
    & .md-list-item-inner
      min-height 52px
  &[data-progress]
    &:after
      content ''
      position absolute
      left 0
      top 0
      bottom 0
      width 0
      background-color $color-almost-black
      z-index -1
  for i in (1..100)
    &[{s('data-progress="%s"', i)}]
      &:after
        width s('%s%', i)

  md-checkbox .md-icon
    border-color $text-color-inverse
  md-checkbox.md-checked .md-icon
    background-color $text-color

.urgency-status
  &.is-error
    color $color-error
  &.is-warning
    color $color-warning
  &.is-info
    color $color-info

*:focus
  outline none!important
*[ng-click]
  cursor pointer

.page-content md-tabs
  md-tabs-wrapper
    background $color-metallic-copper
  md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled])
    color rgba($color-white, .6)
    &.md-active
    &:hover
      color $color-white
  md-ink-bar
    background $color-white

.seach-code-form
  padding 0
  margin -12px 0 0 0
  font-size 1rem
  md-input-container
    position relative
    padding 0
    label
    .md-placeholder
      color rgba($text-color-inverse, .7)
      text-shadow none
      margin-top -4px
    .md-input
      border-color rgba($color-white, .3)
      text-shadow none
    .md-button
      position absolute
      min-width 0
      min-height 0
      line-height 1.5em
      height 1.8em
      padding 0 14px
      right -22px
      bottom 2px

  md-input-container:not(.md-input-invalid).md-input-focused .md-input
    border-color $text-color-inverse

  md-input-container.md-input-focused label:not(.md-no-float)
    color $text-color-inverse
    text-shadow none

.layout-row
.layout-col
  display flex